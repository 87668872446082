import {request} from '@/utils/request'

// 列表
export function getAdminOrder(data) {
  return request('GET','/shop/backend/orderDetails', data, true)
}

// 详情
export function getAdminOrderInfo(id) {
  return request('GET','/shop/backend/orderDetails/' + id, '', true)
}
// 获取支付订单
export function getOrdersPay(id) {
  return request('POST','/shop/backend/orderDetails/logisticsAndPay/' + id, '', true)
}
// 发货
export function postOrderSendSure(id, data) {
  return request('POST','/shop/backend/orderDetails/ship/' + id, data, true)
}
// 发票
export function postOrderInvoice(id, data) {
  return request('POST','/shop/backend/orderDetails/invoice/' + id, data, true)
}
// 驳回
export function postOrderSendCancel(id) {
  return request('POST','/shop/backend/orderDetails/disallowance/' + id, '', true)
}

// 物流
export function getLogistics(data) {
  return request('GET','/shop/logistics', data, true)
}
