<template>
    <div class="admin_page_body">
        <div class="admin_page_item pr0">
            <div class="data_search mt32">
                <el-form ref="listQuery" :inline="true" :model="listQuery" size="small" label-width="85px">
                    <el-form-item label="退款单编号：" prop="detailCode">
                        <el-input v-model="listQuery.detailCode" placeholder="请输入退款单编号"></el-input>
                    </el-form-item>

                    <el-form-item label="订单编号：" prop="goodsName">
                        <el-input v-model="listQuery.goodsName" placeholder="请输入订单编号"></el-input>
                    </el-form-item>

                    <el-form-item label="维权状态：" v-show="isQueryShow" key="3" prop="deliveryType">
                        <el-select v-model="listQuery.deliveryType" placeholder="请选择维权状态">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="物流配送" value="1"></el-option>
                            <el-option label="店面自提" value="2"></el-option>
                        </el-select>
                    </el-form-item>

                    <transition-group name="slide-right">
                        <el-form-item label="申请时间：" v-show="isQueryShow" key="1" prop="date">
                            <el-date-picker
                                    v-model="listQuery.date"
                                    type="daterange"
                                    range-separator="~"
                                    start-placeholder="开始时间"
                                    end-placeholder="结束时间"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="dateOptions">
                            </el-date-picker>
                        </el-form-item>
                    </transition-group>

                    <el-form-item class="last">
                        <el-button type="primary" :loading="isSearchLoading" @click="toSearchList">查 询</el-button>
                        <el-button @click="$refs['listQuery'].resetFields()">重 置</el-button>
                        <el-button type="text" @click="isQueryShow = false" v-if="isQueryShow">收起<i class="el-icon-arrow-up el-icon--right"></i></el-button>
                        <el-button type="text" @click="isQueryShow = true" v-else>展开<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div class="data_table mt18">
                <div class="orders_list">
                    <div class="orders_header">
                        <div class="item" style="flex: 2"><span>商品</span></div>
                        <div class="item" style="flex: 1; text-align: center"><span>单价</span></div>
                        <div class="item" style="flex: 1; text-align: center"><span>数量</span></div>
                        <div class="item" style="flex: 1; text-align: center"><span>退款金额</span></div>
                        <div class="item" style="flex: 1; text-align: center"><span>维权状态</span></div>
                        <div class="item" style="width: 80px; text-align: center"><span>交易操作</span></div>
                    </div>

                    <div class="order_body">
                        <div class="order_body_item" v-for="(item, index) in listData" :key="index">
                            <div class="order_body_header">
                                <div class="info">
                                    <div class="text">退款单编号：{{item.detailCode}}</div>
                                    <div class="text ml16">订单号：{{item.detailCode}}</div>
                                    <div class="text ml16">申请时间：{{item.createdAt}}</div>
                                </div>
                                <div class="text">买家：{{item.userNickname}}<template v-if="item.userCorporateName">（{{item.userCorporateName}}）</template></div>
                                <div class="text" style="color: #999">退货退款</div>
                            </div>

                            <div class="body">
                                <div class="item" style="flex: 4">
                                    <div class="goods_list" v-for="(good, i) in item.goodsInfo" :key="i">
                                        <div class="goods_list_item" style="flex: 2">
                                            <div class="goods_item">
                                                <el-image :src="imageURL + good.goodsImageUrl" class="image" fit="contain"></el-image>
                                                <div class="goods_item_info">
                                                    <div class="name">{{good.goodsName}}</div>
                                                    <div class="text">
                                                        <span class="mr16" v-for="(sku, j) in good.sku" :key="j">{{sku.skuKey}}：{{sku.skuValue}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="goods_list_item" style="flex: 1; text-align: center">
                                            <span class="desc">￥{{good.price}}</span>
                                        </div>
                                        <div class="goods_list_item" style="flex: 1; text-align: center">
                                            <span class="desc">×{{good.num}}</span>
                                        </div>
                                    </div>
                                </div>
<!--                                <div class="item" style="width: 150px">-->
<!--                                    <span class="desc" style="align-items: flex-start;word-wrap:break-word;word-break:break-all">{{item.userAccount}}</span>-->
<!--                                </div>-->
                                <div class="item" style="flex: 1; text-align: center">
                                    <span class="desc">￥{{item.price}}</span>
                                </div>
                                <div class="item" style="flex: 1; text-align: center">
                                    <span class="desc">
                                        <div class="status red" v-if="item.status === 1">待付款</div>
                                        <div class="status yellow" v-if="item.status === 2">待发货</div>
                                        <div class="status blue" v-if="item.status === 3">已发货</div>
                                        <div class="status green" v-if="item.status === 4">已完成</div>
                                        <div class="status info" v-if="item.status === 5">已取消</div>
                                    </span>
                                </div>

                                <div class="item" style="width: 80px; text-align: center">
                                    <div class="btnGroup">
                                        <template v-if="item.channel === 1">
                                            <el-button type="text" style="margin-left: 0" @click="$router.push('/admin/refund/detail')">维权处理</el-button>
                                            <el-button type="text" style="margin-left: 0">维权查询</el-button>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <noDataView v-if="listData.length === 0" />
                </div>
            </div>

            <pagination :size="listQuery.pageSize" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange"/>
        </div>
    </div>
</template>

<script>
  import {getAdminOrder, postOrderSendSure, postOrderSendCancel, getLogistics, postOrderInvoice} from '../../../api/admin/orders'
  import {mapState} from "vuex";
  import {imageURL, uploadURL} from "../../../utils/config";
  import {beforeUploadImage, beforeUploadFile} from "../../../utils/method";
  const pagination = () => import('../../../components/pagination/admin')
  const noDataView = () => import('../../../components/noDataView')
  export default {
    name: "adminRefundIndex",
    data () {
      return {
        type: '',
        listQuery: {
          status: '',
          detailCode: '',
          goodsName: '',
          date: [],
          deliveryType: '',
          userAccount: '',
          page: 1,
          pageSize: 10
        },
        pages: {
          total: 0,
          pages: 0
        },
        isSearchLoading: false,
        isRequestLoading: false,
        isQueryShow: false,
        listData: [],
        imageURL: imageURL,
        uploadURL: uploadURL,
        dateOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now()
          }
        },
        beforeUploadImage: beforeUploadImage,
        beforeUploadFile: beforeUploadFile,
      }
    },
    computed: {
      ...mapState({
        listKeepAlive: state => state.app.listKeepAlive,
      })
    },
    activated () {
      if (this.listKeepAlive.isInitPageNo) {
        this.$store.commit('changeScrollTop');
        this.listQuery.page = 1
      }
      if (this.listKeepAlive.isReset) {
        this.$refs['listQuery'].resetFields()
      }
      if (this.listKeepAlive.isQueryList) {
        this.getList()
      }
      if (this.listKeepAlive.isQuerySelect) {
        this.getLogistics()
      }
    },
    methods: {
      getList () {
        this.isRequestLoading = true
        let start = ''
        let end = ''
        if (this.listQuery.date.length > 0) {
          start = this.listQuery.date[0] + ' 00:00:00'
          end = this.listQuery.date[1] + ' 23:59:59'
        }
        getAdminOrder({
          status: this.listQuery.status,
          detailCode: this.listQuery.detailCode,
          goodsName: this.listQuery.goodsName,
          start: start,
          end: end,
          deliveryType: this.listQuery.deliveryType,
          userAccount: this.listQuery.userAccount,
          page: this.listQuery.page,
          pageSize: this.listQuery.pageSize
        }).then(res => {
          this.isRequestLoading = false
          this.isSearchLoading = false
          if (res.code === 0) {
            this.listData = res.data.list
            this.pages.total = res.data.total
            this.pages.pages = res.data.pages
          }
        }).catch(() => {
          this.isRequestLoading = false
          this.isSearchLoading = false
        })
      },
      pageChange (e) {
        this.listQuery.page = e.current
        this.listQuery.pageSize = e.size
        this.getList()
      },
      toSearchList () {
        this.listQuery.page = 1
        this.isSearchLoading = true
        this.getList()
      },
      getLogistics () {
        getLogistics().then(res => {
          if (res.code === 0) {
            this.logisticsList = res.data
          }
        })
      },
      typeChange (e) {
        this.listQuery.status = e.name
        this.listQuery.page = 1
        this.getList()
      },
      toSendCancel (id) {
        this.$confirm('是否确认驳回?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          postOrderSendCancel(id).then(res => {
            if (res.code === 0) {
              this.$message({
                message: '驳回成功',
                type: 'success',
                showClose: true,
              });
              this.getList()
            }
          })
        }).catch(() => {});
      },
      typeLogisticsChange () {
        this.formSend.logisticsUrl = []
        this.formSend.imageFileList = []
      },
      logisticsUrlUpload (res, file) {
        if (res.code === 0) {
          this.formSend.logisticsUrl.push({
            url: res.data,
            name: file.name
          })
        }
      },
      logisticsUrlRemove (file, fileList) {
        let data = []
        fileList.forEach(item => {
          data.push({
            url: item.response.data,
            name: item.name
          })
        })
        this.formSend.logisticsUrl = data
      },
      toEditLogistics (item) {
        this.formSend.id = item.id
        this.formSend.type = 2
        this.formSend.status = item.logisticsStatus
        this.formSend.desc = item.logisticsReason
        this.formSend.logisticsId = item.logisticsId
        this.formSend.logisticsCode = item.logisticsCode
        this.formSend.logisticsType = item.logisticsType
        this.formSend.logisticsUrl = JSON.parse(item.logisticsUrl)
        let imageFileList = []
        this.formSend.logisticsUrl.forEach((item) =>{
          imageFileList.push({
            name: item.name,
            url: this.imageURL + item.url,
            response: {
              data: item.url
            }
          })
        })
        this.formSend.imageFileList = imageFileList
        this.isPopupSend = true
      },
      submitFormSend (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isSubmitLoading = true
            let logisticsName = this.logisticsList.filter(item => item.id === this.formSend.logisticsId)[0].name

            postOrderSendSure(this.formSend.id, {
              data: {
                logisticsId: this.formSend.logisticsId,
                logisticsName: logisticsName,
                logisticsCode: this.formSend.logisticsCode,
                logisticsType: this.formSend.logisticsType,
                logisticsUrl: JSON.stringify(this.formSend.logisticsUrl),
                type: this.formSend.type,
              }
            }).then(res => {
              this.isSubmitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: this.formSend.type === 1 ? '发货成功' : '修改发货成功',
                  type: 'success',
                  showClose: true,
                });
                this.isPopupSend = false
                this.getList()
              }
            }).catch(() => {
              this.isSubmitLoading = false
            })
          }
        })
      },
      typeInvoiceChange () {
        this.formInvoice.invoiceUrl = []
        this.formInvoice.imageFileList = []
      },
      imageUploadInvoice (res, file) {
        if (res.code === 0) {
          this.formInvoice.invoiceUrl.push({
            url: res.data,
            name: file.name
          })
        }
      },
      imageRemoveInvoice (file, fileList) {
        let data = []
        fileList.forEach(item => {
          data.push({
            url: item.response.data,
            name: item.name
          })
        })
        this.formInvoice.invoiceUrl = data
      },
      submitFormInvoice (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isSubmitLoading = true
            postOrderInvoice(this.formInvoice.id, {
              data: {
                invoiceType: this.formInvoice.invoiceType,
                invoiceUrl: this.formInvoice.invoiceUrl,
                type: this.formInvoice.type,
              }
            }).then(res => {
              this.isSubmitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: this.formInvoice.type === 1 ? '发票上传成功' : '发票修改成功',
                  type: 'success',
                  showClose: true,
                });
                this.isPopupInvoice = false
                this.getList()
              }
            }).catch(() => {
              this.isSubmitLoading = false
            })
          }
        })
      },
      toEditInvoice (item) {
        this.formInvoice.id = item.id
        this.formInvoice.type = 2
        this.formInvoice.invoiceType = item.invoiceType
        this.formInvoice.status = item.invoiceStatus
        this.formInvoice.desc = item.invoiceReason
        Object.assign(this.formInvoice.invoiceUrl, item.invoiceUrl)
        let imageFileList = []
        item.invoiceUrl.forEach((item) =>{
          imageFileList.push({
            name: item.name,
            url: this.imageURL + item.url,
            response: {
              data: item.url
            }
          })
        })
        this.formInvoice.imageFileList = imageFileList
        this.isPopupInvoice = true
      }
    },
    components: {
      pagination, noDataView
    }
  }
</script>
